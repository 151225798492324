import NProgress from "nprogress";

NProgress.configure({
  easing: "linear",
  minimum: 0.1,
  showSpinner: false,
  trickleSpeed: 100,
});

export const loadingBar = {
  done: NProgress.done,
  start: NProgress.start,
};
